<template>
  <div>
    <Banner bg-image="green-bg.jpg" talk-img="get-help-for-myself.png">
      <template v-slot:title> Help for yourself</template>
      <template v-slot:subtitle>
        You have taken an important step towards safety. We want you to get all
        the support you need—it sounds like you’ve been having a really tough
        time. The resources and tools on this app can help you stay safe.
      </template>
      <template v-slot:description>
        Let’s learn more about how you’re feeling in order to provide the most
        appropriate support.
      </template>
    </Banner>

    <section class="my-8 last-sect md:my-12 py-14">
      <div class="max-w-4xl px-4 mx-auto sm:px-6">
        <div class="text-2xl font-bold call-text md:text-3xl text-blue">
          Over the last few weeks, today included…
        </div>
        <p class="mb-6 mt-2.5 text-blue">(Check all that apply)</p>

        <div class="grid grid-cols-1 gap-3 md:text-lg">
          <label v-for="question in questions" :key="question.id">
            <input
              type="checkbox"
              :value="question.id"
              v-model="checkedQuestions"
              class="form-checkbox"
            />
            <span class="text-blue-dark">{{ question.text }}</span>
          </label>
        </div>
      </div>
      <div class="max-w-4xl px-4 pt-8 mx-auto sm:px-6">
        <GreenBtn text="&#160;&#160; Next &#160;&#160;" @click="submit" />
      </div>
    </section>

    <EmergencySupport />
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
import EmergencySupport from "@/components/EmergencySupport.vue";
import GreenBtn from "@/components/GreenBtn.vue";
export default {
  name: "GetHelpForMyself",
  components: {
    Banner,
    EmergencySupport,
    GreenBtn,
  },
  data() {
    return {
      checkedQuestions: [],
      questions: [
        {
          id: 1,
          text: "I have felt unable to cope when things go wrong",
        },
        {
          id: 2,
          text: "I have had difficulty getting to sleep or staying asleep",
        },
        {
          id: 3,
          text: "I have felt overwhelmed",
        },
        {
          id: 4,
          text: "Unwanted images or memories have been distressing me",
        },
        {
          id: 5,
          text: "I have felt despairing or hopeless",
        },
        {
          id: 6,
          text: "I have found myself wishing, hoping, and longing for a permanent escape from life",
        },
        {
          id: 7,
          text: "I have felt that me or my family would be better off if I was dead",
        },
        {
          id: 8,
          text: "I have been having thoughts about killing myself",
        },
        {
          id: 9,
          text: "I have intention of acting on these thoughts of killing myself",
        },
        {
          id: 10,
          text: "I have thought about how I might do this, I have a plan",
        },
      ],
    };
  },
  methods: {
    submit() {
      if (this.checkedQuestions.includes(10)) {
        this.$router.push({ name: "PlanningSuicide" });
      } else if (this.checkedQuestions.includes(9)) {
        this.$router.push({ name: "Intent" });
      } else if (
        this.checkedQuestions.includes(1) ||
        this.checkedQuestions.includes(2) ||
        this.checkedQuestions.includes(3) ||
        this.checkedQuestions.includes(4) ||
        this.checkedQuestions.includes(5) ||
        this.checkedQuestions.includes(6) ||
        this.checkedQuestions.includes(7) ||
        this.checkedQuestions.includes(8)
      ) {
        this.$router.push({ name: "Thoughts" });
      }
    },
  },
};
</script>

<style scoped>
.form-checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  flex-shrink: 0;
  height: 1.1rem;
  width: 1.1rem;
  background-color: #fff;
  border-color: #3c5eaa;
  border-width: 2px;
  border-radius: 0.25rem;
  margin-right: 15px;
}
.form-checkbox:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox=!string!fill=!string!xmlns=!string!%3e%3cpath d=!string!/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: #3c5eaa;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
/* TODO import box shadows and ring offsets */
.form-checkbox:checked:focus {
  border-color: transparent;
  background-color: #3c5eaa;
  outline: none;
  --ring-offset-shadow: 0 0 0 var(--ring-offset-width, 2px)
    var(--ring-offset-color, #fff);
  --ring-shadow: 0 0 0 calc(2px + var(--ring-offset-width, 2px))
    var(--blue, #2563eb);
  box-shadow: var(--ring-offset-shadow), var(--ring-shadow), 0 0 #0000;
  box-shadow: var(--ring-offset-shadow), var(--ring-shadow),
    var(--box-shadow, 0 0 #0000);
}
</style>
